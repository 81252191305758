import React from "react"

import PricePage from "../../components/CatalogCeramic/PricePage"
import { ceramicCars3 } from "../../components/CatalogCeramic/data"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const KatalizatorPage = props => {
  const { location } = props

  const data = ceramicCars3[1]
  const { name, year, engine, type } = data

  return (
    <Layout path={location.pathname}>
      {
        // eslint-disable-next-line
        <SEO
          title={`Катализатор ${name}`}
          description={`Стоимость выкупа катализатора ${name}, год выпуска: ${year}, объем двигателя: ${engine}, тип двигателя: ${type}`}
        />
      }
      <div className="overflow-hidden bg-gray-100 pt-20 lg:pt-16">
        <PricePage data={data} />
      </div>
    </Layout>
  )
}

export default KatalizatorPage
